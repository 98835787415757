import axios from "axios";

const URL = "/exercises";

export default class ExerciseService {
  static async getExercises({
    offset,
    limit,
    searchString,
    lang,
    type,
    difficulty,
  }) {
    const params = new URLSearchParams({ offset, limit });

    const optional = {
      searchString,
      lang,
      type,
      difficulty,
    };

    Object.keys(optional).forEach((param) => {
      if (
        optional[param] !== undefined &&
        optional[param] !== null &&
        optional[param] !== ""
      ) {
        params.append(param, optional[param]);
      }
    });

    const exercises = await axios.get(`${URL}/exercises?${params.toString()}`);

    return exercises;
  }

  static async getExercise({ id }) {
    const exercise = await axios.get(`${URL}/exercises/${id}`);
    return exercise;
  }

  static async updateExercise({
    id,
    title,
    data,
    description,
    difficulty,
    categoryId,
  }) {
    const body = { id, description };

    const optional = {
      title,
      data,
      difficulty,
      categoryId,
    };

    Object.keys(optional).forEach((param) => {
      if (
        optional[param] !== undefined &&
        optional[param] !== null &&
        optional[param] !== ""
      ) {
        body[param] = optional[param];
      }
    });

    const response = await axios.patch(`${URL}/exercises/${id}`, body);

    return response;
  }

  static async createExercise({
    type,
    topic,
    text,
    setting,
    sentencesAmount,
    difficulty,
    lang,
    categoryId,
    trackId,
    trackInterval,
    mediaType,
    vocabulary,
    title,
    description,
  }) {
    const response = await axios.post(`${URL}/exercises/generate`, {
      type,
      topic,
      text,
      setting,
      sentencesAmount,
      difficulty,
      lang,
      categoryId,
      trackId,
      trackInterval,
      mediaType,
      vocabulary,
      title,
      description,
    });
    return response;
  }

  static async generateText({
    grammarTopic,
    textTopic,
    genre,
    lang,
    difficulty,
    vocabulary,
    sentencesAmount,
  }) {
    const response = await axios.post(`${URL}/exercises/generate-text`, {
      grammarTopic,
      textTopic,
      genre,
      lang,
      difficulty,
      vocabulary,
      sentencesAmount,
    });

    return response;
  }

  static async generateWords({ topic, difficulty, lang, wordsAmount }) {
    const response = await axios.post(`${URL}/exercises/generate-words`, {
      topic,
      difficulty,
      lang,
      wordsAmount,
    });

    return response;
  }

  static async keepExercise({ id }) {
    const response = await axios.post(`${URL}/exercises/keep/${id}`, {});

    return response;
  }

  static async deleteExercise({ id }) {
    const response = await axios.delete(`${URL}/exercises/${id}`);
    return response;
  }

  static async removeExercise({ id }) {
    const response = await axios.delete(`${URL}/exercises/remove/${id}`);

    return response;
  }

  static async getSavedExercises({
    offset,
    limit,
    searchString,
    lang,
    type,
    difficulty,
    unassigned,
  }) {
    const params = new URLSearchParams({ offset, limit, lang });

    const optional = {
      searchString,
      type,
      difficulty,
    };

    Object.keys(optional).forEach((param) => {
      if (
        optional[param] !== undefined &&
        optional[param] !== null &&
        optional[param] !== ""
      ) {
        params.append(param, optional[param]);
      }
    });

    if (unassigned) {
      params.append("unassigned", unassigned);
    }

    const exercises = await axios.get(
      `${URL}/exercises/get-saved?${params.toString()}`
    );
    return exercises;
  }

  static async getLessons({ offset, limit, searchString, lang }) {}

  static async getSavedLessons({ offset, limit, searchString, lang }) {
    const params = new URLSearchParams({ offset, limit, lang });

    const optional = {
      searchString,
    };

    Object.keys(optional).forEach((param) => {
      if (
        optional[param] !== undefined &&
        optional[param] !== null &&
        optional[param] !== ""
      ) {
        params.append(param, optional[param]);
      }
    });

    const lessons = await axios.get(
      `${URL}/lessons/get-saved?${params.toString()}`
    );

    return lessons;
  }

  static async getLesson({ id }) {
    const response = await axios.get(`${URL}/lessons/${id}`);
    return response;
  }

  static async createLesson({
    title,
    lang,
    description,
    exam,
    file,
    topic,
    difficulty,
    order,
    age,
  }) {
    const response = await axios.post(`${URL}/lessons`, {
      title,
      lang,
      description,
      exam,
      file,
      order,
      topic,
      difficulty,
      age,
    });
    return response;
  }

  static async keepLesson({ id }) {
    const response = await axios.post(`${URL}/lessons/keep/${id}`, {});

    return response;
  }

  static async updateLesson({ id, picture, pdf, ...params }) {
    const formData = new FormData();

    for (const [key, value] of Object.entries(params)) {
      if (value !== null && value !== undefined && value !== "") {
        formData.append(key, value);
      }
    }
    if (picture) {
      formData.append("pictures", picture, picture.name);
      formData.append("pictures", new Blob(), "eof");
    }
    if (pdf) {
      formData.append("pdfs", pdf, pdf.name);
      formData.append("pdfs", new Blob(), "eof");
    }

    const response = await axios.patch(`${URL}/lessons/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  }

  static async updateLessonOrder({ id, order }) {
    const response = await axios.patch(`${URL}/lessons/${id}/update-order`, {
      order,
    });

    return response;
  }

  static async deleteLesson({ id }) {
    const response = await axios.delete(`${URL}/lessons/${id}`);

    return response;
  }

  static async removeLessonFromSaved({ id }) {
    const response = await axios.delete(`${URL}/lessons/remove/${id}`);

    return response;
  }

  static async addChapterToLesson({
    lessonId,
    chapterId,
    chapterType,
    order,
    title,
  }) {
    const response = await axios.post(
      `${URL}/lessons/${lessonId}/add-chapter`,
      {
        chapterId,
        chapterType,
        order,
        title,
      }
    );
    return response;
  }

  static async deleteChapterFromLesson({ lessonId, chapterId }) {
    const response = await axios.delete(
      `${URL}/lessons/${lessonId}/remove-chapter`,
      {
        data: {
          chapterId,
        },
      }
    );
    return response;
  }

  static async getTeacherLessons({ id }) {
    const lessons = await axios.get(`${URL}/lessons/teacher-lessons/${id}`);

    return lessons;
  }

  static async cloneLesson({ id }) {
    const lessons = await axios.post(`${URL}/lessons/clone/${id}`);

    return lessons;
  }

  static async verify({ id }) {
    const lessons = await axios.patch(`${URL}/exercises/verify/${id}`, {
      verified: true,
    });

    return lessons;
  }
}
