import React, { useState } from "react";
import DemoPageLoader from "../../components/UI/DemoPageLoader/DemoPageLoader";
import cl from "./Button.module.css";

const Button = ({
  variant,
  text,
  icon,
  onClick,
  isDisabled,
  isLoading,
  style,
  size,
}) => {
  const [isPressed, setIsPressed] = useState(false);

  const classNames = [cl.button, cl[variant]];
  if (size) classNames.push(cl[size]);
  if (isPressed) classNames.push(cl.pressed);

  return (
    <button
      disabled={isDisabled}
      style={{ ...style, color: isLoading && "transparent" }}
      className={classNames.join(" ")}
      onClick={onClick}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onMouseLeave={() => setIsPressed(false)}
      onTouchStart={() => setIsPressed(true)}
      onTouchEnd={() => setIsPressed(false)}
    >
      {icon && <div className={cl.icon}>{icon}</div>}
      {text}
      {isLoading && (
        <DemoPageLoader
          size={style?.height ? style.height - 6 : 24}
          color={variant === "dark" ? "#ffffff" : "#cbcbcb"}
        />
      )}
    </button>
  );
};

export default Button;
