import React from "react";
import { FirstSection, Footer, Section } from "./components";

const TeachersTutorial = () => {
  return (
    <>
      <FirstSection />
      <Section index={1} />
      <Section index={2} />
      <Section index={3} />
      <Section index={4} />
      <Section index={5} />
      <Footer />
    </>
  );
};

export default TeachersTutorial;
