import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as IconClose } from "../../assets/svg/lessons-cross_white.svg";
import { Button } from "../";
import cl from "./Select.module.css";

const Select = ({
  value,
  variant,
  size,
  label,
  options = [],
  onChange,
  style,
  height,
  hideReset,
}) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef();

  function handleSelectClick(e) {
    const elClasses = e.target.classList;
    if (
      !elClasses.contains(cl.primarySelect) &&
      !elClasses.contains(cl.arrowUp) &&
      !elClasses.contains(cl.arrowDown) &&
      !elClasses.contains(cl.value)
    ) {
      return;
    }
    setExpanded(!expanded);
  }

  const handleOptionClick = (value) => {
    onChange(value);
    setExpanded(false);
  };

  const getActive = () => options.find((o) => o.value === value);

  const handleResetButton = (e) => {
    e.stopPropagation();
    onChange("");
  };

  const renderResetButton = () => {
    if (!getActive() || hideReset) return null;
    return (
      <div className={cl.resetButton}>
        <Button
          variant={"dark"}
          icon={<IconClose style={{ width: 22 }} />}
          onClick={handleResetButton}
        />
      </div>
    );
  };

  const getDisplayedValue = () => {
    if (!options.length) <div className={cl.value}>{""}</div>;
    if (expanded) return <div className={cl.value}>{label}</div>;

    const current = getActive();
    return current?.name ? (
      <div className={`${cl.value} ${cl.active}`}>{current.name}</div>
    ) : (
      <div className={cl.value}>{label}</div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      className={`${cl.primarySelect} ${expanded ? cl.expanded : ""} ${
        cl[variant]
      } ${size ? cl[size] : ""}`}
      style={style}
      onClick={handleSelectClick}
      ref={ref}
    >
      {getDisplayedValue()}
      {renderResetButton()}
      {!expanded ? (
        <div className={cl.arrowDown}></div>
      ) : (
        <>
          <div className={cl.arrowUp}></div>
          <div className={cl.options} style={height ? { height } : {}}>
            {options.map((o) => (
              <div
                className={cl.optionCont}
                key={o.value}
                onClick={() => handleOptionClick(o.value)}
              >
                <p
                  className={`${cl.option} ${
                    value === o.value ? cl.active : ""
                  }`}
                >
                  {o.name}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Select;
