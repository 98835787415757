import React from "react";
import { ReactComponent as AppStoreLogo } from "../../../../assets/svg/appstore-download-btn_alt.svg";

import { ReactComponent as PlayerMarketLogo } from "../../../../assets/svg/playmarket-download-btn_alt.svg";
import cl from "./DownloadButtons.module.css";
import {
  APPSTORE_LINK,
  PLAYMARKET_LINK,
} from "../../../Landing/components/DownloadButton/DownloadButton";

const DownloadButtons = ({ size }) => {
  return (
    <div className={`${cl.buttons} ${cl[size]}`}>
      <a
        className={cl.button}
        href={APPSTORE_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <AppStoreLogo />
      </a>
      <a
        className={cl.button}
        href={PLAYMARKET_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <PlayerMarketLogo />
      </a>
    </div>
  );
};

export default DownloadButtons;
