import React, { useEffect, useRef, useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import lessonsStore from "../../store/lessonsStore";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import { MicroButton, Select } from "../../../../teacherComponents";
import { LanguageLevels } from "../../../../data/common";
import { ChapterPreview, DeleteConfirmation } from "..";
import {
  Ages,
  AgeOptions,
  LevelOptions,
  getExamOptions,
} from "../../../TeacherContent/data/constants";
import exercisesStore from "../../../TeacherContent/store/exercisesStore";
import { ReactComponent as IconBin } from "../../../../assets/svg/lessons-bin.svg";
import { ReactComponent as IconEdit } from "../../../../assets/svg/lessons-edit.svg";
import { ReactComponent as IconArrow } from "../../../../assets/svg/lessons-arrow.svg";
import { PrimaryInput } from "../../../../UI";
import { Chapters, StudentLessonStatuses, Views } from "../../data/constants";
import teachersStore from "../../../Teachers/store/teachersStore";
import cl from "./Lesson.module.css";

const Lesson = ({ lessonObj, themeId, index }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { id, chapters, age, difficulty, exam, title } = lessonObj;
  const pdfs = lessonObj.pdfs ?? [];
  const pictures = lessonObj.pictures ?? [];

  const { themes, currentStudentLessons, collapseCards, filterByStudent } =
    lessonsStore;
  const { currentStudent } = exercisesStore;
  const { currentTeacher } = teachersStore;

  const isTeacher = exercisesStore.view === Views.Teachers && currentTeacher;

  const ref = useRef();

  const [newTitle, setNewTitle] = useState(title ?? "");
  const [newAge, setNewAge] = useState(age ?? "");
  const [newDifficulty, setNewDifficulty] = useState(difficulty ?? "");
  const [newExam, setNewExam] = useState(exam ?? "");

  const [isDelete, setIsDelete] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(collapseCards);
  const [isEdit, setIsEdit] = useState(false);

  const [updateLesson] = useFetching(
    async ({ id, title, age, difficulty, exam }) => {
      lessonsStore.updateThemeLesson({
        ...lessonObj,
        title,
        age,
        difficulty,
        exam,
      });

      setIsEdit(false);
      await ExerciseService.updateLesson({
        id,
        title,
        age,
        difficulty,
        exam,
      });
    }
  );

  const [deleteLesson] = useFetching(async ({ id }) => {
    // TODO: check if we need to delete lessons permanently. @saratovkin
    await ExerciseService.deleteLesson({ id });
  });

  const getStatus = () =>
    currentStudentLessons.find((l) => l.lessonId === id)?.status ?? "";

  const handleClick = () => {
    if (isEdit) return;
    lessonsStore.setCurrentLesson({ ...lessonObj });
  };

  const handleDeleteButton = (e) => {
    e.stopPropagation();
    setIsDelete(true);
  };

  const handleDelete = () => {
    const currentTheme = themes.find((t) => t.id === themeId);
    if (!currentTheme) return;

    lessonsStore.deleteThemeLesson(id);
    deleteLesson({ id });
    setIsDelete(false);
    setIsEdit(false);
  };

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleEditMode = () => {
    setIsEdit(true);
  };

  const handleUpdate = async () => {
    if (!newTitle) return;

    await updateLesson({
      id,
      title: newTitle,
      age: newAge,
      exam: newExam,
      difficulty: newDifficulty,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && isEdit) {
        handleUpdate();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isEdit, newTitle, newAge, newExam, newDifficulty, id]);

  const getFileChapterTitle = (files, type) => {
    const parts = [];

    const count = files.length;
    if (count) {
      parts.push(
        t(count === 1 ? `exercises.${type}_file` : `exercises.${type}_files`)
      );
      parts.push(`(${count})`);
    }

    return parts.join(" ");
  };

  const isEmpty = () => {
    if (chapters?.length) return false;
    if (pdfs.length) return false;
    if (pictures.length) return false;
    return true;
  };

  const getSelectVariant = () => {
    const activeStatuses = [
      StudentLessonStatuses.Unfinished,
      StudentLessonStatuses.Done,
    ];
    const currentStatus = getStatus();
    return activeStatuses.includes(currentStatus) ? "white" : "grey";
  };

  const getChapterVariant = () => {
    if (isEdit) return "transparent";
    const activeStatuses = [
      StudentLessonStatuses.Unfinished,
      StudentLessonStatuses.Done,
    ];
    const currentStatus = getStatus();
    if (!currentStudent || !activeStatuses.includes(currentStatus))
      return "grey";
    return "white";
  };

  const renderStatus = () => {
    const currentStatus = getStatus();
    if (!currentStudent || !currentStatus) return null;
    return (
      <div className={`${cl.status} ${cl[currentStatus]}`}>
        {t(`exercises.${currentStatus}`)}
      </div>
    );
  };

  const renderChapters = () => {
    if (isEmpty() || isCollapsed) return null;

    return (
      <div className={cl.chapters}>
        {chapters.map((c) => (
          <ChapterPreview
            key={c.id}
            chapter={c}
            variant={getChapterVariant()}
          />
        ))}
        {[...pdfs].length !== 0 && (
          <ChapterPreview
            chapter={{
              title: getFileChapterTitle(pdfs, "pdf"),
              type: Chapters.File,
            }}
            variant={getChapterVariant()}
          />
        )}
        {[...pictures].length !== 0 && (
          <ChapterPreview
            chapter={{
              title: getFileChapterTitle(pictures, "pictures"),
              type: Chapters.File,
            }}
            variant={getChapterVariant()}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    setNewTitle(title ?? "");
    setNewAge(age ?? "");
    setNewDifficulty(difficulty ?? "");
    setNewExam(exam ?? "");
  }, [isEdit]);

  useEffect(() => {
    setIsCollapsed(collapseCards);
  }, [collapseCards]);

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={filterByStudent}>
      {(provided, snapshot) => (
        <div
          className={`${cl.lessonCard} ${getStatus() ? cl[getStatus()] : ""} ${
            isEdit ? cl.locked : ""
          }`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div
            className={cl.cardInner}
            ref={ref}
            onClick={handleClick}
            style={{
              opacity: snapshot.isDragging ? 0.9 : 1,
              transform: snapshot.isDragging ? "rotate(-2deg)" : "",
            }}
          >
            {isEdit ? (
              <div className={cl.editor} onClick={(e) => e.stopPropagation()}>
                <PrimaryInput
                  placeholder={t("exercises.lesson_title")}
                  style={{ width: "100%" }}
                  value={newTitle}
                  onChange={setNewTitle}
                  autoFocus={true}
                />
                <div className={cl.selectContainer}>
                  <Select
                    variant={getSelectVariant()}
                    size={"narrow"}
                    label={t("users.age")}
                    value={newAge}
                    options={AgeOptions}
                    onChange={setNewAge}
                    hideReset
                  />
                  <Select
                    variant={getSelectVariant()}
                    size={"narrow"}
                    label={t("exercises.exam")}
                    value={newExam}
                    options={getExamOptions(lang)}
                    onChange={setNewExam}
                    hideReset
                  />
                  <Select
                    variant={getSelectVariant()}
                    size={"narrow"}
                    label={t("users.level")}
                    value={newDifficulty}
                    options={LevelOptions}
                    onChange={setNewDifficulty}
                    hideReset
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={cl.titleContainer}>
                  <p className={cl.title}>{title}</p>
                  <div
                    className={cl.controlButtons}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {!isTeacher && (
                      <>
                        <MicroButton
                          icon={<IconBin />}
                          variant={"red"}
                          size={"small"}
                          onClick={handleDeleteButton}
                        />
                        <MicroButton
                          icon={<IconEdit style={{ filter: "grayscale(1)" }} />}
                          variant={"white"}
                          size={"small"}
                          onClick={handleEditMode}
                        />
                      </>
                    )}
                    <MicroButton
                      icon={
                        <IconArrow
                          style={{
                            filter: "grayscale(1)",
                            transform: isCollapsed ? "rotate(180deg)" : "",
                          }}
                        />
                      }
                      variant={"white"}
                      size={"small"}
                      onClick={handleCollapse}
                    />
                  </div>
                  {renderStatus()}
                </div>
                <div className={cl.infoContainer}>
                  {isCollapsed && (
                    <p className={cl.statItem}>{`${chapters?.length ?? "0"} ${
                      chapters?.length === 1 ? "section" : "sections"
                    }`}</p>
                  )}
                  {typeof age === "number" && (
                    <p className={cl.statItem}>{Ages[age]}</p>
                  )}
                  {typeof difficulty === "number" && (
                    <p className={cl.statItem}>{LanguageLevels[difficulty]}</p>
                  )}
                  {exam && <p className={cl.statItem}>{exam}</p>}
                </div>
              </>
            )}

            {renderChapters()}
          </div>
          <DeleteConfirmation
            title={t(`exercises.delete_lesson_confirmation_title`)}
            visible={isDelete}
            setVisible={setIsDelete}
            onDelete={handleDelete}
          />
        </div>
      )}
    </Draggable>
  );
};

export default observer(Lesson);
