import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../router";
import DownloadButtons from "../DownloadButtons/DownloadButtons";
import {
  TEACHER_NAVIGATION,
  TeacherLink,
} from "../../../../components/Header/Header";
import { ReactComponent as TeacherLogo } from "../../../../assets/svg/lessons-logo.svg";
import { ReactComponent as TeacherLogoText } from "../../../../assets/svg/lessons-logo-text.svg";
import cl from "./Footer.module.css";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={cl.footer}>
      <div className={cl.navigationBlock}>
        <TeacherLink
          to={ROUTES.teacherTutorial}
          style={{
            minWidth: 100,
            width: 100,
          }}
        >
          <TeacherLogo className={cl.teacherLogo} />
          <TeacherLogoText className={cl.teacherLogoText} />
        </TeacherLink>
        <div className={cl.navigation}>
          {TEACHER_NAVIGATION.map((l, i) => (
            <NavLink key={l.to} className={cl.link} to={l.to}>
              {t(l.text)}
            </NavLink>
          ))}
        </div>
      </div>
      <div className={cl.underlineBlock}>
        <div className={cl.underline}>
          <p className={cl.link}>©Pony, 2024</p>
          <p className={cl.link}>{t("landing.commercial_terms")}</p>
          <p className={cl.link}>{t("landing.contact_us")}</p>
        </div>
        <DownloadButtons size={"small"} />
      </div>
    </div>
  );
};

export default Footer;
