import React from "react";
import cl from "./Typography.module.css";

const VARIANTS = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
};

const Typography = ({
  variant,
  color,
  children,
  style,
  className,
  dangerouslySetInnerHTML,
}) => {
  const Text = VARIANTS[variant] || "h5";

  return (
    <Text
      className={`${cl[variant]} ${className || ""}`}
      style={{ color, ...style }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </Text>
  );
};

export default Typography;
