import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext, InterfaceContext } from "../../App";
import { interfaceLangs } from "../../data/interfaceLangs";
import studentsStore from "../../pages/TeacherStudents/store/studentsStore";
import { PrimaryButton, PrimarySelect, StyledNavLink } from "../../UI";
import { ROUTES } from "../../router";
import BackPageArrow from "../UI/BackPageArrow/BackPageArrow";
import { ReactComponent as TeacherLogo } from "../../assets/svg/lessons-logo.svg";
import { ReactComponent as TeacherLogoText } from "../../assets/svg/lessons-logo-text.svg";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as IconLogout } from "../../assets/img/icon-logout.svg";
import { ReactComponent as IconProfile } from "../../assets/img/icon-profile.svg";
import { ReactComponent as IconTeacherProfile } from "../../assets/svg/lessons-profile.svg";
import { Roles } from "../../data/common";
import cl from "./Header.module.css";

const HEADER_EXCLUDED_PAGES = [ROUTES.default, ROUTES.demo];

const ADMIN_NAVIGATION = [
  { to: ROUTES.library, text: "navbar.tracks" },
  { to: ROUTES.podcasts, text: "navbar.podcasts" },
  { to: ROUTES.users, text: "navbar.users" },
  { to: ROUTES.languages, text: "navbar.languages" },
  { to: ROUTES.dictionary, text: "navbar.dictionary" },
  { to: ROUTES.situations, text: "navbar.situations" },
  { to: ROUTES.healthcheck, text: "navbar.services" },
  { to: ROUTES.notify, text: "navbar.notify" },
];

export const TEACHER_NAVIGATION = [
  { to: ROUTES.lessons, text: "navbar.lessons" },
  { to: ROUTES.students, text: "navbar.students" },
  { to: ROUTES.teachers, text: "navbar.teachers" },
  { to: ROUTES.teacherExercises, text: "navbar.exercises" },
  { to: ROUTES.teacherTracks, text: "navbar.tracks" },
  { to: ROUTES.teacherSituations, text: "navbar.situations" },
  { to: ROUTES.teacherDictionary, text: "navbar.dictionaries" },
];

const Header = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { notifications } = studentsStore;
  const [notifyValue, setNotifyValue] = useState("");
  const location = useLocation();
  const { appInterface } = useContext(InterfaceContext);
  const { t, i18n } = useTranslation();

  const logout = () => {
    auth.logout(navigate);
  };

  useEffect(() => {
    if (window.location.href.indexOf("login") >= 0) {
      let userLangs = navigator.languages;
      interfaceLangs.forEach((lang) => {
        if (userLangs.includes(lang.value)) {
          appInterface.setLanguage(lang);
          return;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!notifications.length) return;

    const total = notifications.map((n) => n.exercises).flat().length;
    setNotifyValue(total > 100 ? "99+" : total.toString());
  }, [notifications]);

  const isHeaderVisible =
    !HEADER_EXCLUDED_PAGES.includes(location.pathname) &&
    !location.pathname.includes(ROUTES.share_situation) &&
    !location.pathname.includes(ROUTES.share_dictionary);

  const isLogin = location.pathname.includes("login");

  if (!isHeaderVisible) {
    return null;
  }

  if (auth.role === Roles.Teacher) {
    return (
      <header className={cl.teacherHeader}>
        <div className={cl.teacherHeaderInner}>
          <TeacherLink
            to={ROUTES.teacherTutorial}
            style={{
              minWidth: 100,
              width: 100,
            }}
          >
            <TeacherLogo className={cl.teacherLogo} />
            <TeacherLogoText className={cl.teacherLogoText} />
          </TeacherLink>
          {TEACHER_NAVIGATION.map((l) => (
            <TeacherLink
              key={l.to}
              to={l.to}
              text={t(l.text)}
              notifyValue={l.to === ROUTES.students ? notifyValue : ""}
            />
          ))}
          <div className={cl.teacherProfileBtns}>
            <div className={cl.currentLanguage}>
              {i18n.language.toUpperCase()}
            </div>
            <TeacherLink to={ROUTES.profile}>
              <IconTeacherProfile />
            </TeacherLink>
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className={cl.header}>
      <div className={cl.headerInner}>
        <BackPageArrow />
        <StyledNavLink
          to={ROUTES.default}
          style={{
            marginLeft: 10,
            marginRight: auth.role !== "guest" ? 60 : 35,
            minWidth: 80,
            width: 80,
            position: "relative",
            top: 3,
          }}
        >
          <Logo style={{ display: "block" }} />
        </StyledNavLink>
        {auth.role === Roles.Admin && (
          <>
            {ADMIN_NAVIGATION.map((l, i) => (
              <StyledNavLink
                key={l.to}
                to={l.to}
                text={t(l.text)}
                showDivider={i !== ADMIN_NAVIGATION.length - 1}
                showUnderline
              />
            ))}
          </>
        )}
        {auth.role === Roles.Teacher && (
          <>
            {TEACHER_NAVIGATION.map((l, i) => (
              <StyledNavLink
                key={l.to}
                to={l.to}
                text={t(l.text)}
                showDivider={i !== TEACHER_NAVIGATION.length - 1}
                showUnderline
              />
            ))}
          </>
        )}
        {[Roles.Admin, Roles.Teacher, Roles.Marketing].includes(auth.role) && (
          <>
            <div className={cl.profileBtns}>
              <StyledNavLink to={ROUTES.profile}>
                <PrimaryButton
                  variant={"dark"}
                  icon={
                    <IconProfile
                      style={{ height: 20, width: 20, minWidth: 20 }}
                    />
                  }
                  text={auth.user?.name || auth.user?.email?.split("@")[0]}
                  style={{ padding: "8px 24px" }}
                />
              </StyledNavLink>
              {auth.role === Roles.Teacher && (
                <div className={cl.currentLanguage}>
                  {i18n.language.toUpperCase()}
                </div>
              )}
              <PrimaryButton
                variant={"dark"}
                icon={<IconLogout />}
                onClick={logout}
                style={{ width: 36, height: 36, padding: "unset" }}
              />
            </div>
          </>
        )}
        {isLogin && (
          <PrimarySelect
            value={i18n.language}
            options={interfaceLangs}
            onChange={appInterface.setLanguage}
          />
        )}
      </div>
    </header>
  );
};

export const TeacherLink = ({ to, style, children, text, notifyValue }) => {
  return (
    <>
      <NavLink
        className={({ isActive }) => {
          return `${cl.teacherLink}  ${isActive ? cl.active : ""}`;
        }}
        to={to}
        style={style}
      >
        {text || children}
        {notifyValue && <div className={cl.notification}>{notifyValue}</div>}
      </NavLink>
    </>
  );
};

export default observer(Header);
