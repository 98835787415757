import React, { useState } from "react";
import cl from "./Checkbox.module.css";

const Checkbox = ({ label, value, onChange, style, variant }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleOnChange = () => {
    if (onChange) onChange(!value);
  };

  return (
    <label className={cl.label} style={style}>
      <input
        type={"checkbox"}
        checked={value ? value : false}
        className={`${cl.checkbox} ${isPressed ? cl.pressed : ""} ${
          variant ? cl[variant] : ""
        }`}
        onChange={handleOnChange}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        onMouseLeave={() => setIsPressed(false)}
        onTouchStart={() => setIsPressed(true)}
        onTouchEnd={() => setIsPressed(false)}
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
