import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconArrow } from "../../../../assets/svg/lessons-arrow.svg";
import cl from "./SectionButton.module.css";

const SectionButton = ({ index }) => {
  const { t } = useTranslation();
  return (
    <div className={cl.sectionButton}>
      <p className={cl.buttonText}>
        {t(`teacher_tutorial.section_${index}_button`)}
      </p>
      <IconArrow className={cl.arrow} />
    </div>
  );
};

export default SectionButton;
