import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../router";
import { Button } from "../../../../teacherComponents";
import { Typography } from "..";
import gradesRightSrc from "../../../../assets/webp/grades_1.webp";
import gradesLeftSrc from "../../../../assets/webp/grades_2.webp";
import playerPlug from "../../../../assets/png/player-plug.png";
import cl from "./FirstSection.module.css";

const FirstSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [leftImgLoaded, setLeftImgLoaded] = useState(false);
  const [rightImgLoaded, setRightImgLoaded] = useState(false);

  const handleStartButtonClick = () => {
    navigate(ROUTES.lessons);
  };

  return (
    <div className={cl.firstSection}>
      <div className={cl.sectionInner}>
        <img
          className={`${cl.gradesImg} ${leftImgLoaded ? cl.loaded : ""}`}
          src={gradesLeftSrc}
          alt="grades"
          onLoad={() => setLeftImgLoaded(true)}
        />
        <Typography variant={"h1"} className={cl.mainTitle}>
          {t("teacher_tutorial.title")}
        </Typography>
        <Button
          variant={"dark"}
          style={{ height: 56, width: 250, fontSize: 17, borderRadius: 12 }}
          text={t("teacher_tutorial.get_started")}
          onClick={handleStartButtonClick}
        />
        <img
          className={`${cl.gradesImg} ${rightImgLoaded ? cl.loaded : ""}`}
          src={gradesRightSrc}
          alt="grades"
          onLoad={() => setRightImgLoaded(true)}
        />
      </div>
      <div className={cl.videoButton}>
        <img src={playerPlug} alt="player" />
        <div className={cl.buttonText}>
          <p>{t("teacher_tutorial.video_title")}</p>
          <p>{t("teacher_tutorial.video_subtitle")}</p>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
