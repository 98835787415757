import React, { useEffect } from "react";
import { motion, useAnimate, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import { DownloadButton, PageCont, Typography } from "../../components";
import PurpleGradient from "../../../../assets/webp/gradient-purple.webp";
import { ReactComponent as ThumbsUp } from "../../../../assets/svg/thumbs-up.svg";
import { ReactComponent as PhoneLandscape } from "../../../../assets/svg/phone-usage-landscape.svg";
import { ReactComponent as PhonePortrait } from "../../../../assets/svg/phone-usage-portrait.svg";
import cl from "./DownloadView.module.css";
import { BaseUrl } from "../../../../data/common";

const DownloadView = () => {
  const { t } = useTranslation();

  const [bodyRef, animateBody] = useAnimate();
  const [buttonsRef, animateButtons] = useAnimate();
  const isInView = useInView(bodyRef);

  useEffect(() => {
    if (isInView) {
      animateBody(
        bodyRef.current,
        { scale: 1, opacity: 1 },
        { duration: 1.5, delay: 0.5 }
      );
      animateButtons(
        buttonsRef.current,
        { y: 0, opacity: 1 },
        { duration: 1.5, delay: 0.5 }
      );
    }
  }, [isInView]);

  return (
    <PageCont color={"black"}>
      <motion.div
        layout
        ref={bodyRef}
        key="downloadBody"
        initial={{ scale: 0.95, opacity: 0 }}
        className={cl.container}
      >
        <ThumbsUp className={cl.decoration} />
        <PhonePortrait className={cl.decoration} />
        <PhoneLandscape className={cl.decoration} />
        <motion.div
          layout
          className={cl.textContainer}
          ref={buttonsRef}
          key="downloadButtons"
          initial={{ y: 50, opacity: 0 }}
        >
          <Typography variant={"h3"} color={"#ffffff"}>
            {t("new_landing.download_title")}
          </Typography>
          <div className={cl.buttons}>
            <DownloadButton
              variant={"light"}
              size={"large"}
              type={"appstore"}
            />
            <DownloadButton
              variant={"light"}
              size={"large"}
              type={"playmarket"}
            />
          </div>
          <div className={cl.footer}>
            <Typography
              variant={"h6"}
              color={"#ebebf54d"}
              className={cl.buttonText}
            >
              {t("new_landing.download_subtitle")}
            </Typography>
            <div className={cl.navigation}>
              <a href={`${BaseUrl}/support`}>
                <Typography
                  variant={"h6"}
                  color={"#ebebf54d"}
                  className={cl.text}
                >
                  {"Contact Us"}
                </Typography>
              </a>
              <Typography
                variant={"h6"}
                color={"#ebebf54d"}
                className={cl.text}
              >
                {"© 2023 / Pony"}
              </Typography>
            </div>
          </div>
        </motion.div>
      </motion.div>
      <img src={PurpleGradient} className={cl.gradient} alt="" />
    </PageCont>
  );
};

export default DownloadView;
