import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import studentsStore from "./store/studentsStore";
import { ChapterPlug, ContentContainer } from "../TeacherContent/components";
import { useFetching } from "../../hooks/useFetching";
import UserService from "../../api/UserService";
import {
  AddStudentModal,
  CurrentStudent,
  StudentCard,
  StudentSearchBar,
} from "./components";
import { LanguageLevels } from "../../data/common";
import { Loader } from "../../UI";
import { Faces } from "./data/faces";
import { ViewTitle } from "../../teacherComponents";
import cl from "./TeacherStudents.module.css";

const TeacherStudents = () => {
  const { t } = useTranslation();
  const { currentStudent, difficulty, studentSearch, notifications } =
    studentsStore;

  const [students, setStudents] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [comments, setComments] = useState({});

  const [getStudents, studentsLoading] = useFetching(async () => {
    const { data } = await UserService.getStudents();
    const options = data.items.map((s, i) => ({
      id: s.id,
      name: s.name?.length ? s.name : t("exercises.unnamed_student"),
      level: LanguageLevels[s.level] ?? "??",
      nativeLang: s.nativeLang ?? "??",
      targetLang: s.lang ?? "??",
      profilePic: Faces[i],
    }));

    setStudents(options);
    studentsStore.setCurrentStudent(options[0] ?? null);
  });

  const handleCommentChange = (text) => {
    if (!currentStudent) return;

    const { id } = currentStudent;
    const newComments = { ...comments, [id]: text };

    setComments(newComments);
    localStorage.setItem("mockStudentComments", JSON.stringify(newComments));
  };

  const getStudentNotification = (id) => {
    return notifications.find((n) => n.studentId === id);
  };

  useEffect(() => {
    getStudents();

    const existingComments =
      JSON.parse(localStorage.getItem("mockStudentComments")) || {};
    setComments(existingComments);
  }, []);

  useEffect(() => {
    setFiltered(
      students.filter((s) => {
        const matchesName = studentSearch
          ? s.name.toLowerCase().includes(studentSearch.toLowerCase())
          : true;

        const matchesDifficulty = difficulty ? s.level === difficulty : true;

        return matchesName && matchesDifficulty;
      })
    );
  }, [difficulty, studentSearch, students]);

  return (
    <>
      <ViewTitle text={t("navbar.students")} />
      <ContentContainer>
        <div className={cl.aside}>
          <StudentSearchBar />
          <div className={cl.list}>
            {studentsLoading ? (
              <Loader style={{ margin: "auto" }} />
            ) : (
              <>
                {filtered.map((s) => (
                  <StudentCard
                    key={s.id}
                    student={s}
                    isActive={s.id === currentStudent?.id}
                    notifications={getStudentNotification(s.id)}
                    setCurrent={studentsStore.setCurrentStudent}
                  />
                ))}
                <AddStudentModal />
              </>
            )}
          </div>
        </div>
        {currentStudent ? (
          <CurrentStudent
            student={currentStudent}
            comment={comments[currentStudent.id]}
            onClose={() => studentsStore.setCurrentStudent(null)}
            onChange={handleCommentChange}
          />
        ) : (
          <ChapterPlug style={{ maxWidth: "70%" }} />
        )}
      </ContentContainer>

    </>
  );
};

export default observer(TeacherStudents);
