import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import SituationsService from "../../../../api/SituationsService";
import ExerciseService from "../../../../api/ExerciseService";
import ProgressService from "../../../../api/ProgressService";
import exercisesStore from "../../store/exercisesStore";
import teachersStore from "../../../Teachers/store/teachersStore";
import DemoPageLoader from "../../../../components/UI/DemoPageLoader/DemoPageLoader";
import { Chapters, Views } from "../../../TeacherLessons/data/constants";
import {
  ExerciseIcon,
  ExerciseMediaIcon,
} from "../../views/ExercisesView/components";
import { mapChapterData } from "../../helpers/mapChapterData";
import { ReactComponent as IconAdd } from "../../../../assets/svg/lessons-add.svg";
import { ReactComponent as IconBin } from "../../../../assets/svg/lessons-bin.svg";
import { ReactComponent as IconFolder } from "../../../../assets/svg/lessons-folder.svg";
import cl from "./ChapterCard.module.css";
import { MediaExerciseTypes } from "../../data/constants";

const ChapterCard = ({
  type,
  chapter,
  isActive,
  setActive,
  lessonId,
  onDelete,
  onSelect,
  isScrollable,
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { currentStudent, currentStudentChapters, view } = exercisesStore;
  const { currentTeacher } = teachersStore;

  const isTeacher = view === Views.Teachers && currentTeacher;

  const [studentProgress, setStudentProgress] = useState({});
  const [mappedChapter, setMappedChapter] = useState({});
  const [situationInfo, setSituationInfo] = useState();
  const [words, setWords] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const { id, title, preview, params = [] } = mappedChapter;

  const [getSituationInfo, infoLoading] = useFetching(async () => {
    const { data } = await SituationsService.getSituationInfo({
      id: chapter.id,
      language: lang.toUpperCase(),
    });
    setSituationInfo(data?.info ?? []);
  });

  const [getWords, wordsLoading] = useFetching(async () => {
    const { data } = await ProgressService.getCategoryWords({
      categoryId: chapter.id,
      lang,
    });
    setWords(data.items);
  });

  const [deleteChapterFromLesson, deleteChapterLoading] = useFetching(
    async () => {
      await ExerciseService.deleteChapterFromLesson({
        lessonId,
        chapterId: id,
      });

      onDelete(id);
    }
  );

  const [deleteChapterFromFav] = useFetching(async () => {
    switch (type) {
      case Chapters.Track: {
        await ProgressService.deleteTrack({
          id,
        });
        break;
      }
      case Chapters.Situation: {
        await ProgressService.deleteSituation({
          id,
          lang,
        });

        break;
      }
      case Chapters.Exercise: {
        try {
          await ExerciseService.removeExercise({
            id,
          });
        } catch (e) {
          try {
            await ExerciseService.deleteExercise({
              id,
            });
          } catch (e) {
            throw e;
          }
        }
        break;
      }
      case Chapters.Dictionary: {
        await ProgressService.deleteWordsCategory({ id });
        break;
      }
      default:
        return;
    }
    setActive && setActive();
    onDelete(id);
  });

  const isEmptyWordsCategory = () =>
    type === Chapters.Dictionary && !chapter?.words?.length;

  const handleChapterClick = () => {
    if (!setActive || isActive) return;
    setActive(
      type === Chapters.Situation
        ? { ...chapter, info: chapter.info ?? situationInfo }
        : chapter
    );
  };

  const handleChapterSelect = () => {
    if (isEmptyWordsCategory()) return;
    onSelect();
  };

  const renderProgress = () => {
    if (
      !currentStudent ||
      !lessonId ||
      !currentStudentChapters ||
      !studentProgress
    )
      return null;
    switch (type) {
      case Chapters.Track:
        return (
          <div className={cl.trackProgress}>
            <div
              className={cl.progressFill}
              style={{ width: `${(1 - studentProgress.progress) * 100}%` }}
            />
          </div>
        );
      case Chapters.Situation:
        const progress = [
          studentProgress.listeningCompleted,
          studentProgress.puzzleCompleted,
          studentProgress.speakingCompleted,
          studentProgress.translationCompleted,
        ].map((p) => (typeof p === "number" ? p : 0));

        return progress.map((p, i) => (
          <div className={cl.situationProgress} key={i}>
            <div
              className={cl.progressFill}
              style={{
                width: `${(1 - p) * 100}%`,
              }}
            />
          </div>
        ));
      case Chapters.Exercise: {
        const options =
          studentProgress.data?.options ||
          studentProgress.data?.questions ||
          studentProgress.data?.sentences ||
          [];
        return options.map((_o, i) => (
          <div className={cl.exerciseProgress} key={i}>
            <div
              className={cl.progressFill}
              style={{
                // TODO add correct progress display after data.options rework @saratovkin
                width: `0%`,
              }}
            />
          </div>
        ));
      }
      case Chapters.Dictionary:
        return <></>;
      case Chapters.File:
      default:
        return null;
    }
  };

  const getChapterType = () => {
    if (chapter.trackId && chapter.trackInterval) return "video";
    if (Object.values(MediaExerciseTypes).includes(chapter.exerciseType))
      return "text";
    if (chapter.text) return "text";
    return chapter.exerciseType || chapter.type;
  };

  const renderProgressStatus = () => {
    if (
      type !== Chapters.Exercise ||
      !studentProgress ||
      !studentProgress.status
    )
      return null;
    const { status } = studentProgress;
    return (
      <>
        <p className={`${cl.status} ${cl[status]}`}>
          {t(`exercises.${status}`)}
        </p>
        <span className={cl.dot}>{""}</span>
      </>
    );
  };

  useEffect(() => {
    if (!currentStudent) {
      setStudentProgress({});
      return;
    }
    const { dictionaries, exercises, situations, tracks } =
      currentStudentChapters;
    switch (type) {
      case Chapters.Track:
        setStudentProgress(tracks?.find((t) => t.trackId === chapter.id));
        break;
      case Chapters.Situation:
        setStudentProgress(
          situations?.find((s) => s.situationId === chapter.id)
        );
        break;
      case Chapters.Exercise:
        setStudentProgress(exercises?.find((e) => e.id === chapter.id));
        break;
      case Chapters.Dictionary:
        setStudentProgress(dictionaries?.find((d) => d.id === chapter.id));
        break;
      case Chapters.File:
      default:
        setStudentProgress([]);
    }
  }, [currentStudentChapters, type]);

  useEffect(() => {
    if (type === Chapters.Situation && !chapter.info) {
      getSituationInfo();
    } else {
      setSituationInfo();
    }
    if (type === Chapters.Dictionary && !chapter.wordsCount) {
      getWords();
    } else {
      setWords();
    }
    setMappedChapter(
      mapChapterData({ ...chapter, ...studentProgress }, type, t)
    );
  }, [chapter, studentProgress]);

  return (
    <div
      className={`${cl.chapterCard} ${isActive ? cl.active : ""} ${
        lessonId ? cl.lesson : ""
      } ${cl[type]} ${isScrollable ? cl.scrollable : ""} ${
        isTeacher ? cl.teacher : ""
      }`}
      onClick={handleChapterClick}
    >
      <div className={`${cl.imageContainer} ${isLoaded ? cl.loaded : ""}`}>
        {preview && (
          <>
            {!isLoaded && <DemoPageLoader size={12} color={"#ffffff"} />}
            <img src={preview} alt={title} onLoad={() => setIsLoaded(true)} />
          </>
        )}
        {type === Chapters.Dictionary && (
          <>
            <div className={cl.folder}>
              <IconFolder />
            </div>
            <p className={cl.count}>
              {chapter?.wordsCount ??
                chapter?.words?.length ??
                words?.length ??
                "?"}
            </p>
          </>
        )}
        {type === Chapters.Exercise && (
          <>
            <ExerciseIcon type={chapter.exerciseType || chapter.type} />
            <ExerciseMediaIcon type={getChapterType()} />
          </>
        )}
      </div>
      <div className={cl.cardInner}>
        <p className={cl.title}>{title}</p>
        <div className={cl.infoContainer}>
          {renderProgressStatus()}
          {params.map((p, i) => (
            <p className={cl.label} key={i}>
              {p}
            </p>
          ))}
        </div>
      </div>
      {currentStudent && !onSelect && (
        <div className={cl.progressContainer}>{renderProgress()}</div>
      )}
      {onSelect ? (
        <div
          className={`${cl.slideButton} ${cl.green} ${
            isEmptyWordsCategory() ? cl.disabled : ""
          }`}
          onClick={handleChapterSelect}
        >
          <IconAdd />
        </div>
      ) : (
        <div
          className={`${cl.slideButton} ${cl.red}`}
          onClick={lessonId ? deleteChapterFromLesson : deleteChapterFromFav}
        >
          <IconBin />
        </div>
      )}
    </div>
  );
};

export default observer(ChapterCard);
