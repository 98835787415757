import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import exercisesStore from "../TeacherContent/store/exercisesStore.js";
import {
  TeacherContent,
  TeacherLessons,
  Teachers,
  TeacherStudents,
  TeachersTutorial,
} from "..";
import { Chapters, Views } from "../TeacherLessons/data/constants.js";
import ProgressService from "../../api/ProgressService.js";
import { useFetching } from "../../hooks/useFetching.js";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper.jsx";
import { LessonModal } from "../TeacherLessons/components/index.js";
import studentsStore from "../TeacherStudents/store/studentsStore.js";
import cl from "./TeachersView.module.css";

const TeachersView = ({ type }) => {
  const [getNotifications] = useFetching(async () => {
    const { data } = await ProgressService.getNotifications();
    studentsStore.setNotifications(data.items ?? []);
  });

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    exercisesStore.setView(type);
  }, [type]);

  return (
    <>
      <LessonModal />
      {type === Views.Tutorial && <TeachersTutorial />}
      <PageWrapper
        additionalClass={type === Views.Lessons ? cl.lessons : cl.hidden}
      >
        <TeacherLessons />
      </PageWrapper>
      <PageWrapper
        additionalClass={type === Views.Teachers ? cl.active : cl.hidden}
      >
        <Teachers />
      </PageWrapper>
      <PageWrapper
        additionalClass={type === Views.Students ? cl.active : cl.hidden}
      >
        <TeacherStudents />
      </PageWrapper>
      <PageWrapper
        additionalClass={
          Object.values(Chapters).includes(type) ? cl.active : cl.hidden
        }
      >
        <TeacherContent type={type} />
      </PageWrapper>
    </>
  );
};

export default observer(TeachersView);
