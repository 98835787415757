const BaseUrl =
  window.location.hostname === "thepony.app"
    ? "https://thepony.app"
    : "https://dev.thepony.app";

const DefaultTitle = "Pony - Learn Language with YouTube";

const LanguageLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];

const Roles = {
  Admin: "admin",
  User: "user",
  Teacher: "teacher",
  Guest: "guest",
  // TODO: temporary solution. remove or refactor after marketing research @saratovkin
  Marketing: "marketing",
};

const TicksInSecond = 10000000;

export { BaseUrl, DefaultTitle, LanguageLevels, Roles, TicksInSecond };
