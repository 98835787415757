import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../../App";
import { useFetching } from "../../../../../../hooks/useFetching";
import SituationsService from "../../../../../../api/SituationsService";
import MyModal from "../../../../../../components/UI/MyModal/MyModal";
import {
  Button,
  Checkbox,
  CloseButton,
  Input,
  Label,
  Select,
  ToggleButton,
} from "../../../../../../teacherComponents";
import FileUploader, {
  FileTypes,
} from "../../../../../../components/UI/FileUploader/FileUploader";
import {
  GENDERS,
  LEVELS,
  MODALITIES,
} from "../../../../../SituationsAdministration/data/constants";
import { ICONS } from "../../../../../SituationsAdministration/data/icons";
import { ReactComponent as IconCloud } from "../../../../../../assets/svg/lessons-upload.svg";
import DefaultFile from "../../../../../../assets/webp/situation-cover.webp";
import cl from "./GenerateSituationModal.module.css";

const DefaultModality = ["Friendly"];

const GenerateSituationModal = ({
  categories,
  language,
  visible,
  onGenerate,
  setVisible,
}) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [response, setResponse] = useState([]);
  const [addFile, setAddFile] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [level, setLevel] = useState("");
  const [maleModalities, setMaleModalities] = useState(DefaultModality);
  const [femaleModalities, setFemaleModalities] = useState(DefaultModality);
  const [requestText, setRequestText] = useState("");
  const [file, setFile] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const isSendDisabled = () => {
    if (![...maleModalities, ...femaleModalities].length) return true;
    return false;
  };

  const formatSituationsByModalities = () => {
    const userId = auth.user?.id;
    return [
      ...maleModalities.map((modality) => ({
        title: "",
        categoryId,
        requestText: requestText.toLowerCase(),
        language: language.toUpperCase(),
        gender: GENDERS[0],
        modality,
        level,
        userId,
      })),
      ...femaleModalities.map((modality) => ({
        title: "",
        categoryId,
        requestText: requestText.toLowerCase(),
        language: language.toUpperCase(),
        gender: GENDERS[1],
        modality,
        level,
        userId,
      })),
    ];
  };

  const [addSituation, isSituationLoading, error, resetError] = useFetching(
    async () => {
      resetError();
      const situations = formatSituationsByModalities();

      const { data } = await SituationsService.addSituationsBatch({
        situations,
        file,
      });
      if (data && data.length) {
        onGenerate();
        setResponse(data);
      }
    }
  );

  const handleAddImage = () => {
    setAddFile(true);
  };

  const renderInfoIcons = () => {
    return [
      ...maleModalities.map((m, i) => {
        const modality = m.toLowerCase();
        const key = `male_icon_${i}`;
        return (
          <div className={cl.infoObj} key={key}>
            {"M"}
            <div className={`${cl.icon} ${cl[modality]}`}>
              {ICONS[modality]}
            </div>
            {level}
          </div>
        );
      }),
      ...femaleModalities.map((m, i) => {
        const modality = m.toLowerCase();
        const key = `female_icon_${i}`;
        return (
          <div className={cl.infoObj} key={key}>
            {"F"}
            <div className={`${cl.icon} ${cl[modality]}`}>
              {ICONS[modality]}
            </div>
            {level}
          </div>
        );
      }),
    ];
  };

  const getModality = (modality, gender) => {
    const modalities =
      gender === GENDERS[0] ? maleModalities : femaleModalities;
    const flag = modalities.includes(modality);
    return flag;
  };

  const setModality = (modality, gender) => {
    const setModalities =
      gender === GENDERS[0] ? setMaleModalities : setFemaleModalities;

    setModalities([modality]);
  };

  const setDefaultFile = async () => {
    const fileResponse = await fetch(DefaultFile);
    const blob = await fileResponse.blob();
    setFile(blob);
  };

  const handleSituationGenerate = () => {
    setFormErrors({});

    setTimeout(() => {
      const errors = {
        level: !level,
        requestText: !requestText,
        categoryId: !categoryId,
      };
      setFormErrors(errors);

      if (Object.values(errors).some((e) => e)) return;

      addSituation();
    });
  };

  useEffect(() => {
    setLevel("");
    setMaleModalities(DefaultModality);
    setFemaleModalities(DefaultModality);
    setRequestText("");
    setDefaultFile();
    setResponse([]);
  }, [visible]);

  useEffect(() => {
    resetError();
  }, [level, requestText]);

  useEffect(() => {
    if (!response || !response.length) return;
    if (response.some((s) => s.status === 400)) {
    } else {
      setVisible(false);
    }
  }, [response]);

  useEffect(() => {
    const options = categories.map((c) => ({ name: c.title, value: c.id }));
    setCategoryOptions(options);
    setCategoryId(options[0]?.value);
  }, [categories]);

  useEffect(() => {
    setFormErrors({});
  }, [categoryId, level, requestText]);

  return (
    <>
      <MyModal
        className={cl.generateModal}
        visible={visible}
        setVisible={setVisible}
      >
        <p className={cl.title}>{t("situations.situation_add_title")}</p>
        <p className={cl.subtitle}>{t("situations.situation_add_desc")}</p>
        <div>
          <Label
            text={t("situations.request")}
            isError={formErrors["requestText"]}
          />
          <Input
            value={requestText}
            variant={
              formErrors["requestText"] ? "erroredSmall" : "outlinedSmall"
            }
            onChange={setRequestText}
            placeholder={t("situations.request_example")}
          />
        </div>

        <div className={cl.horizontalContainer}>
          <div style={{ flexGrow: 1 }}>
            <Label
              text={t("exercises.category")}
              isError={formErrors["categoryId"]}
            />
            <Select
              variant={"grey"}
              label={t("exercises.select_words_category")}
              value={categoryId}
              options={categoryOptions}
              onChange={setCategoryId}
              style={{ width: "100%" }}
              height={300}
            />
          </div>
          <div>
            <Label
              text={t("exercises.exercise_level")}
              isError={formErrors["level"]}
            />
            <ToggleButton
              variant={formErrors["level"] ? "error" : "outlined"}
              isGrid
              options={LEVELS}
              selectedOption={level}
              setOption={setLevel}
            />
          </div>
        </div>

        <div className={cl.horizontalContainer} style={{ marginTop: 30 }}>
          <p className={cl.label}> {t("situations.male")}</p>
          {MODALITIES.map((m, i) => (
            <Checkbox
              variant={"checkmark"}
              key={`male_${m}`}
              style={{ width: 100 }}
              label={t(`situations.${m.toLowerCase()}`)}
              value={getModality(m, GENDERS[0])}
              onChange={() => setModality(m, GENDERS[0])}
            />
          ))}
        </div>

        <div className={cl.horizontalContainer}>
          <p className={cl.label}> {t("situations.female")}</p>
          {MODALITIES.map((m) => (
            <Checkbox
              variant={"checkmark"}
              key={`female_${m}}`}
              style={{ width: 100 }}
              label={t(`situations.${m.toLowerCase()}`)}
              value={getModality(m, GENDERS[1])}
              onChange={() => setModality(m, GENDERS[1])}
            />
          ))}
        </div>
        <div
          className={`${cl.imageCont} ${file ? "" : cl.placeholder}`}
          onClick={handleAddImage}
        >
          {error && (
            <p className={cl.error}>{t("situations.conflict_error")}</p>
          )}
          {file ? (
            <>
              {requestText && <p className={cl.entityTitle}>{requestText}</p>}
              <img src={URL.createObjectURL(file)} alt={"situation preview"} />
              <div className={cl.infoCont}>{renderInfoIcons()}</div>
              <div className={cl.changeCoverButton}>
                <IconCloud style={{ minWidth: 16 }} />
                {t("courses.change_cover")}
              </div>
            </>
          ) : (
            <Button
              style={{ gap: 12 }}
              icon={<IconCloud style={{ minWidth: 16 }} />}
              text={t("courses.add_cover")}
              variant="grey"
              onClick={() => {}}
            />
          )}
        </div>
        <Button
          style={{ margin: "0 auto" }}
          variant={"dark"}
          text={t("situations.generate")}
          onClick={handleSituationGenerate}
          isLoading={isSituationLoading}
          isDisabled={isSendDisabled()}
        />
        <FileUploader
          type={FileTypes.Image}
          visible={addFile}
          setVisible={setAddFile}
          key="categoryImagePicker"
          onSave={setFile}
          isLessons
        />
        <CloseButton onClick={() => setVisible(false)} />
      </MyModal>
    </>
  );
};

export default GenerateSituationModal;
