import React from "react";
import { useTranslation } from "react-i18next";
import { DownloadButtons, SectionButton, Typography } from "../";
import number1 from "../../../../assets/webp/number_1.webp";
import number2 from "../../../../assets/webp/number_2.webp";
import number3 from "../../../../assets/webp/number_3.webp";
import number4 from "../../../../assets/webp/number_4.webp";
import number5 from "../../../../assets/webp/number_5.webp";
import cl from "./Section.module.css";

const Section = ({ index }) => {
  const { t } = useTranslation();

  const getImageSrc = () => {
    switch (index) {
      case 1:
        return number1;
      case 2:
        return number2;
      case 3:
        return number3;
      case 4:
        return number4;
      case 5:
        return number5;
      default:
        return "";
    }
  };

  return (
    <div className={cl.section}>
      <div className={cl.sectionInner}>
        <img
          className={cl.sectionNumber}
          src={getImageSrc()}
          alt={`number ${index}`}
        />
        <Typography variant={"h2"} style={{ marginBottom: 24 }}>
          {t(`teacher_tutorial.section_${index}_title`)}
        </Typography>

        {index === 1 && (
          <>
            <Typography variant={"h3"} style={{ maxWidth: "95%" }}>
              {t(`teacher_tutorial.section_${index}_subtitle_1`)}
            </Typography>
            <Typography
              variant={"h3"}
              style={{ maxWidth: "95%", color: "#b770ff" }}
            >
              {t(`teacher_tutorial.section_${index}_subtitle_2`)}
            </Typography>
          </>
        )}

        {index === 2 && (
          <Typography variant={"h3"} style={{ maxWidth: "70%" }}>
            {t(`teacher_tutorial.section_${index}_subtitle`)}
          </Typography>
        )}
        {index === 3 && <DownloadButtons size={"large"} />}
      </div>
      <SectionButton index={index} />
    </div>
  );
};

export default Section;
