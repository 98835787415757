import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import exercisesStore from "../../store/exercisesStore";
import { useFetching } from "../../../../hooks/useFetching";
import ProgressService from "../../../../api/ProgressService";
import SituationsService from "../../../../api/SituationsService";
import {
  GenerateSituationModal,
  SelectSituationModal,
  Situation,
  SituationAlert,
} from "./components";
import {
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersList,
  ContentContainer,
} from "../../components";
import {
  ChapterModals,
  Chapters,
} from "../../../TeacherLessons/data/constants";

const SituationsView = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const {
    chaptersModalType,
    difficulty,
    situationSearch,
    unassigned,
    situationCategory,
  } = exercisesStore;

  const [isGenerated, setIsGenerated] = useState(false);
  const [categories, setCategories] = useState([]);
  const [situations, setSituations] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [currentSituation, setCurrentSituation] = useState();
  const [isFilter, setIsFilter] = useState(false);

  const [getSituations, isLoading] = useFetching(async () => {
    const { data } = await ProgressService.getSituations({
      lang,
      unassigned,
    });
    const ids = data.items.map((s) => s.situationId);

    const { data: categories } = await SituationsService.getAvailableCategories(
      {
        language: lang.toUpperCase(),
      }
    );

    setCategories(categories);

    const situations = categories
      .map((c) => c.situations)
      .flat()
      .filter((s) => ids.includes(s.id));

    setSituations(situations);
  });

  const [addFavoriteSituation] = useFetching(async (situationId) => {
    const { data } = await ProgressService.addSituation({
      situationId,
      language: lang,
    });
    if (!data || !data.situationId) {
      getSituations();
      return;
    }

    const newSituation = categories
      .map((c) => c.situations)
      .flat()
      .find((s) => s.id === data.situationId);
    if (!newSituation) {
      getSituations();
      return;
    }
    setSituations([newSituation, ...situations]);
    setCurrentSituation(newSituation);
  });

  const handleSituationDelete = (id) => {
    setSituations(situations.filter((s) => s.id !== id));
  };

  useEffect(() => {
    setCurrentSituation();
    getSituations();
  }, [lang, unassigned]);

  useEffect(() => {
    const filteredSituations = situations.filter((s) => {
      const matchesName = situationSearch
        ? s.title.toLowerCase().includes(situationSearch.toLowerCase())
        : true;

      const matchesDifficulty = difficulty
        ? s.level.includes(difficulty)
        : true;

      const matchesCategory = situationCategory
        ? s.categoryId === situationCategory
        : true;

      return matchesName && matchesDifficulty && matchesCategory;
    });
    setFiltered(filteredSituations);
    setIsFilter(difficulty || situationSearch);
  }, [difficulty, situationSearch, situations, situationCategory]);

  useEffect(() => {
    setCurrentSituation(filtered[0]);
  }, [filtered]);

  useEffect(() => {
    if (isGenerated) {
      setTimeout(() => {
        setIsGenerated(false);
      }, 5000);
    }
  }, [isGenerated]);

  return (
    <ContentContainer view={Chapters.Situation}>
      <ChaptersList
        type={Chapters.Situation}
        isLoading={isLoading}
        chapters={filtered}
        onDelete={handleSituationDelete}
        current={currentSituation}
        setCurrent={setCurrentSituation}
        isFilter={isFilter}
      />
      {currentSituation ? (
        <ChapterPreviewContainer>
          <Situation situation={currentSituation} />
        </ChapterPreviewContainer>
      ) : (
        <ChapterPlug />
      )}
      <SelectSituationModal
        visible={chaptersModalType === Chapters.Situation}
        setVisible={exercisesStore.setChaptersModalType}
        onClick={addFavoriteSituation}
        alreadyAdded={situations}
      />
      <GenerateSituationModal
        categories={categories}
        language={lang}
        onGenerate={() => setIsGenerated(true)}
        visible={chaptersModalType === ChapterModals[Chapters.Situation]}
        setVisible={exercisesStore.setChaptersModalType}
      />
      <SituationAlert
        show={isGenerated}
        onClose={() => setIsGenerated(false)}
      />
    </ContentContainer>
  );
};

export default observer(SituationsView);
