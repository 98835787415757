import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { StudentsSelect } from "../../../TeacherContent/components";
import lessonsStore from "../../store/lessonsStore";
import exercisesStore from "../../../TeacherContent/store/exercisesStore";
import { LanguageLevels } from "../../../../data/common";
import { AgeOptions } from "../../../TeacherContent/data/constants";
import {
  Button,
  ControlButton,
  Input,
  Select,
  Separator,
  ToggleButton,
  Toolbar,
} from "../../../../teacherComponents";
import { Views } from "../../data/constants";
import { ReactComponent as IconAdd } from "../../../../assets/svg/lessons-add.svg";
import teachersStore from "../../../Teachers/store/teachersStore";
import cl from "./LessonSearchBar.module.css";

const LessonSearchBar = () => {
  const { t } = useTranslation();
  const { age, collapseCards, difficulty, isAddLesson, searchString } =
    lessonsStore;
  const { currentTeacher } = teachersStore;
  const { view } = exercisesStore;

  const isTeacher = view === Views.Teachers && currentTeacher;

  const handleCollapseClick = () => {
    lessonsStore.toggleCollapseCards();
  };

  const handleAddLesson = () => {
    if (isAddLesson) return;
    lessonsStore.setIsAddLesson(true);
  };

  return (
    <div className={cl.searchContainer}>
      <Toolbar>
        {!isTeacher && (
          <>
            <Button
              variant={"white"}
              icon={<IconAdd className={cl.plusIcon} />}
              text={t("exercises.add_theme")}
              onClick={handleAddLesson}
            />
            <Separator />
          </>
        )}
        <ToggleButton
          nullable
          options={LanguageLevels}
          selectedOption={difficulty}
          setOption={lessonsStore.setDifficulty}
          variant={"round"}
        />
        <Select
          variant={"white"}
          style={{ minWidth: 140 }}
          label={t("users.age")}
          value={age}
          options={
            typeof age === "number"
              ? [{ value: "", name: t("podcasts.all") }, ...AgeOptions]
              : AgeOptions
          }
          onChange={lessonsStore.setAge}
        />
        <Separator />
        <Input
          search
          placeholder={t("library.search")}
          value={searchString}
          onChange={lessonsStore.setSearchString}
        />
        {!isTeacher && <StudentsSelect />}
        <Separator />
        <ControlButton
          type={collapseCards ? "uncollapse" : "collapse"}
          onClick={handleCollapseClick}
        />
        <ControlButton type="help" style={{ cursor: "not-allowed" }} />
      </Toolbar>
    </div>
  );
};

export default observer(LessonSearchBar);
