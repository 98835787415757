import React from "react";
import { PrimaryButton } from "../../UI";
import { ReactComponent as IconClose } from "../../assets/svg/lessons-cross_alt.svg";

const CloseButton = ({ onClick, top, right, size }) => (
  <PrimaryButton
    onClick={onClick}
    icon={<IconClose />}
    variant={"light"}
    style={{
      width: size ?? 14,
      height: size ?? 14,
      minWidth: size ?? 14,
      padding: "unset",
      background: "transparent",
      position: "absolute",
      right: right ?? 20,
      top: top ?? 20,
      filter: "grayscale(1)",
    }}
  />
);

export default CloseButton;
